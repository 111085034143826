body, html {
  background-color: #f9f9f9;
  font-family: Merriweather, serif;
}

h1 {
  margin-top: 1.75em;
  margin-bottom: .75em;
}

p {
  -webkit-hyphens: auto;
  hyphens: auto;
  -o-hyphens: auto;
}

li {
  padding: .3em;
}

.hideme {
  opacity: 0;
  transition: opacity 2s, transform 2s;
  transform: translateY(2rem);
}

.hideme.abs {
  transition: translateY;
}

.hideme.visible {
  opacity: 1;
  transform: none;
}

main > header {
  z-index: 999;
  background-color: #e8e8e8;
  border-bottom: 1px solid #adb5bd;
  position: sticky;
  top: 0;
}

figure.right {
  float: right;
  width: 25%;
  margin: 1em;
}

.icon {
  border: 1px solid #d3d3d3;
  width: 2em;
  height: 2em;
  padding: 4px;
}

@media (width >= 992px) {
  .columns {
    column-count: 2;
    column-gap: 3em;
  }
}

#ueberuns strong {
  font-size: large;
}

.form-group label {
  margin-bottom: 0;
}

.anchor {
  visibility: hidden;
  display: block;
  position: relative;
  top: -100px;
}

footer {
  text-align: center;
  padding-bottom: 10px;
}

footer a {
  color: gray;
}

.neu {
  color: #fff;
  background: #8b0000;
  margin: 4px;
  padding: 1px 12px;
  display: inline-block;
  transform: rotate(-15deg);
}

/*# sourceMappingURL=index.5326471c.css.map */

body,
html {
  font-family: "Merriweather", serif;
  background-color: #f9f9f9;
}

h1 {
  margin-top: 1.75em;
  margin-bottom: 0.75em;
}

p {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

li {
  padding: 0.3em;
}

.hideme {
  opacity: 0;
  transform: translateY(2rem);
  transition: opacity 2s, transform 2s;
}

.hideme.abs {
  transition: translateY 0s;
}

.hideme.visible {
  opacity: 1;
  transform: none;
}

main > header {
  background-color: hsl(0deg 0% 91%);
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #adb5bd;
}

figure.right {
  float: right;
  width: 25%;
  margin: 1em;
}

.icon {
  width: 2em;
  height: 2em;
  padding: 4px;
  border: 1px solid lightgray;
}

@media (min-width: 992px) {
  .columns {
    column-count: 2;
    column-gap: 3em;
  }
}

#ueberuns strong {
  font-size: large;
}

.form-group label {
  margin-bottom: 0;
}

.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

footer {
  padding-bottom: 10px;
  text-align: center;
}

footer a {
  color: grey;
}

.neu {
  margin: 4px;
  background: darkred;
  color: white;
  padding: 1px 12px;
  transform: rotate(-15deg);
  display: inline-block;
}
